'use strict';

module.exports = {
    applySlick: function (elemToActUpon) {
        if (!$(elemToActUpon).length) return;

        $(elemToActUpon).slick({
            lazyLoad: 'ondemand',
            dots: false,
            arrows: true,
            infinite: true,
            pauseOnHover: true,
            speed: 1500,
            autoplay: true,
            autoplaySpeed: 2500,
            slidesToShow: 6,
            slidesToScroll: 1,
            centerMode: true,
            variableWidth: true,
            responsive: [
                {
                    breakpoint: 800,
                    settings: {
                        arrows: false,
                        slidesToShow: 4
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        arrows: false,
                        slidesToShow: 1
                    }
                }
            ]
        });

        return this;
    },

    hideButtonPlaceholder: function (elemToActUpon) {
        if (!$(elemToActUpon).length) return;

        $(elemToActUpon).addClass('hidden__block');

        return this;
    },

    resetSettings: function (elemToActUpon) {
        if (!$(elemToActUpon).length) return;

        $(elemToActUpon).css({
            overflow: 'hidden'
        });

        return this;
    }

    // init: function() {
    //     this.applySlick('.home-page-partner-companies-logos-items').hideButtonPlaceholder('.home-page-partner-companies-arrows').resetSettings('.home-page-partner-companies-logos-wrapper');
    // }
};
